<template>
  <dash-page-new
      :title="!noRights ? entity.name || '' : $t('Error') "
      :root="!noRights ? entity.course_name || '' : $t('Dashboard')"
      :root-location="entity.course_id ? businessDashLink('courses/' + entity.course_id + '/student') : businessDashLink('')"
      :icon="entity.entity_type === 'video' ? 'mdi-video-outline' : 'mdi-file-outline'"
      :subtitle="entity.entity_type ? $t(capitalizeFirstChar(entity.entity_type) ) : null "
      :loading="loading"
      :no-content-condition="noRights"
      :side-navigation="courseNavigation"
      navigation-route="courses"
      :navigation-return-route="`courses/${STUDENT_COURSE.uuid}/student`"
  >


    <template #header_action.desktop>
      <v-btn  v-if="entity.entity_type === 'video'"
          @click="openVideo" dark class="noCaps" :color="wsACCENT">
        <v-icon>mdi-play</v-icon>
        {{ $t('OpenInVideoPlayer') }}
      </v-btn>
    </template>

    <template #no-content>
     <v-sheet
         :style="`border : 1px solid ${wsBACKGROUND}`"
         :class="!SM ? 'd-flex' : null"
         class="wsRoundedLight pa-6 mt-5  align-center justify-space-between" :color="wsLIGHTCARD">
       <h5>
         <v-icon class="mr-1" :color="wsWARNING">mdi-alert-octagon</v-icon>
         {{ $t('NoRightsToViewContent')}}
       </h5>
       <v-btn
           @click="$router.push(businessDashLink(''))"
           :block="SM"
           :class="[{ 'mt-3' : SM }]"
           outlined class="noCaps" :color="wsACCENT">{{ $t('BackToCourses') }}</v-btn>
     </v-sheet>
    </template>

    <template #default>

      <v-divider class="pb-5 mt-3" :style="`border-color : ${wsBACKGROUND}`" />

      <!-- Components-->
      <div class="pt-5">
        <lecture-viewer
            v-if="entity.entity_type === 'lecture' "
            :component="entity"
        />

        <task-viewer
            v-if="['task','task_video','task_test'].includes(entity.entity_type) "
            @update="handleComponentUpdate"
            :component="entity"
        />
        <file-viewer
            v-if="['video','file'].includes(entity.entity_type) "
            :component="entity"
        />
      </div>

      <!-- Navigation-->
      <component-navigation
          v-if="entity.uuid"
          class="mt-10"
          :component="entity"
      />
      <component-comments
          v-if="entity.uuid && entity.has_comments"
          :component="entity"
      />



    </template>


  </dash-page-new>
</template>

<script>

import componentNavigation from "@/components/pages/westudy/student/StudentCourse/UI/componentNavigation";
import componentComments from "@/components/pages/westudy/student/StudentCourse/UI/componentComments";
import {mapActions} from "vuex";
import lectureViewer from "@/components/pages/westudy/student/StudentCourse/components/lecture/lectureViewer";
import taskViewer from "@/components/pages/westudy/student/StudentCourse/components/task/taskViewer";
import fileViewer from "@/components/pages/westudy/student/StudentCourse/components/fileViewer";

export default {
  name: "studentFile",
  props : {
    uuid : {
      type : String
    }
  },
  components : {
    lectureViewer,
    taskViewer,
    fileViewer,
    componentNavigation,
    componentComments
  },
  data() {
    return {
      entity : {},
      loading : true,
      noRights : false
    }
  },
  computed : {
    courseNavigation() {

      let items = []
      if ( !this.$store.state.courses.studentCourse.modules) {
        return []
      }

      this.$store.state.courses.studentCourse.modules.forEach(module => {
        if ( Array.isArray(module.topics) ) {
          module.topics.forEach(topic => {

            items.push({
              text: module.name,
              isCategory : true
            })


            if ( Array.isArray(topic.components)  ) {

              items.push({
                text: topic.name,
                isSubCategory : true
              })

              topic.components.forEach(component => {

                if (component.is_permitted) {

                  items.push({
                    text: component.name,
                    value: component.uuid,
                    path: 'component/' + component.uuid,
                    icon: this.getEntityTypeIcon(component),
                    iconColor: !component.passed ? this.wsDARKLIGHT : null,
                    passed: 'result is : ' + component.passed
                  })
                }

              })
            }

          })
        }

      })

      return items
    },
  },
  watch : {
    uuid() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('courses',['GET_STUDENT_COMPONENT']),

    handleComponentUpdate(component) {
      this.entity = this.COPY(component)
      this.PROCESS_COMPONENT_COMPLETION(this.entity)
    },
    getEntityTypeIcon( component ) {
      let type = component.entity_type

      if (this.hasAudio(component.file) ) {
        return 'mdi-volume-high'
      }

      let icon = ''
      switch ( type ) {
        case 'lecture'    :  icon = 'mdi-book-open-page-variant'       ; break;
        case 'task'       :  icon = 'mdi-content-paste'                ; break;
        case 'task_video' :  icon = 'mdi-cast-education'               ; break;
        case 'test'       :  icon = 'mdi-order-bool-ascending-variant' ; break;
        case 'task_test'  :  icon = 'mdi-order-bool-ascending-variant' ; break;
        case 'video'      :  icon = 'mdi-video'                        ; break;
        case 'file'       :  icon = 'mdi-file'                         ; break;
        default : icon = 'mdi-bookmark'
      }
      return icon

    },
    hasAudio(file) {
      if ( !file ) {
        return false
      }
      if ( !file.mime) {
        return false
      }
      return file.mime.includes('audio')

    },

    openVideo() {
      this.$store.state.videoPlayer.component = this.entity
      this.$store.state.videoPlayer.display = true
    },
    async initPage() {
      if ( !this.uuid ) {
        return
      }
      this.loading = true

      let result = await this.GET_STUDENT_COMPONENT(this.uuid)
      if ( !result ) {
        this.loading = false
        this.noRights = true
        this.entity = {}
        return
      }
      this.noRights = false
      this.entity = result
      this.loading = false

      this.$store.state.dashboard.pageNavigation = this.uuid

      this.PROCESS_COMPONENT_COMPLETION(this.entity)

    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>