<template>
  <div>
    <!-- Test Task Content -->
    <v-sheet
        v-if="entity.type === 'test' && entity.test_id"
        :style="`border : 1px solid ${wsBACKGROUND}`"
        :color="wsLIGHTCARD"
        class="wsRoundedHalf pa-6 mb-5"
    >

      <div class="d-flex justify-space-between">
        <div class="d-flex align-center">
          <v-icon :color="wsACCENT" class="mr-2">mdi-format-list-checks</v-icon>
          <h3 v-if="!SM" class="font-weight-medium">{{ $t('Test')}} <span class="font-weight-bold text-capitalize">{{ entity.test_name }}</span> </h3>
          <h4 v-if="SM" class="font-weight-medium">{{ $t('Test')}} <span class="font-weight-bold text-capitalize">{{ entity.test_name }}</span> </h4>
        </div>
        <div>
          <v-chip v-if="getSuccess" dark  :color="wsSUCCESS">
            <h3 class="px-3">{{ $t('Success') }}</h3>
          </v-chip>
        </div>
      </div>


      <div v-if="entity.test_unlimited && !getTestInProcess" class="mt-3" style="width : 100%">

        <div class="d-flex " v-if="getStartNewTest">
          <h5 v-if="!SM" ><span :style="`color : ${wsACCENT}`">{{ $t('TriesLeft')}}:</span> {{ entity.test_tries }} </h5>
          <h5 v-if="SM"  ><span :style="`color : ${wsACCENT}`">{{ $t('TriesLeft')}}:</span> {{ entity.test_tries }} </h5>
        </div>
        <h5 v-else >{{$t('TestNoMoreTries')}}</h5>
      </div>
      <div v-if="getTestInProcess" class="mt-6">
        <h3 class="text-center">{{$t('TestInProcess')}}</h3>
      </div>
      <div  v-else-if="entity.student_test" >
        <h5 class="mt-3">
          <span :style="`color : ${wsACCENT}`">{{ $t('WorstResult')}}:</span>
          {{ getWorstTestResult }}
        </h5>
        <h5 class="mt-3">
          <span :style="`color : ${wsACCENT}`">{{ $t('BestResult')}}:</span>
          {{ getBestTestResult }}
        </h5>

      </div>


      <v-btn v-if="getStartNewTest || getTestInProcess"
             :to="businessDashLink('courses/test/' + entity.test_id)"
             block large class="mt-6 noCaps"
             :color="wsACCENT"
             href="entity.video_url"
             outlined
      >
        {{ getTestStartText }}
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>

    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "videoTaskViewer",
  props : {
    entity : {
      type : Object,
      default() { return {} }
    }
  },
  computed : {
    getStartNewTest() {

      if (!this.entity.test_unlimited) { return true }
      if ( this.entity.test_tries < 1 ) { return false }
      return true;
    },
    getSuccess() {
      if ( this.entity.student_test) {
        if (  this.entity.student_test.length > 0 ) {
          let hasSuccess = this.entity.student_test.find(el=> el.status === 'success')
          if ( hasSuccess ) { return true }
        }
      }

      return false
    },
    getTestInProcess() {
      if ( this.entity.student_test ) {
        let inProcess = this.entity.student_test.find(el => el.status === 'in_process')
        if ( inProcess ) {
          return true
        }
      }
      return false
    },
    getTestStartText() {
      if ( this.getTestInProcess ) {
        return this.$t('Proceed')
      }
      return this.$t('StartTest')
    },
    getBestTestResult() {
      if ( !this.entity.student_test ) {
        return null
      }
      let items = this.entity.student_test.map( el => el.result )
      let sorted = items.sort( (a,b) => a - b )

      return sorted[0]
    },
    getWorstTestResult() {
      if ( !this.entity.student_test ) {
        return null
      }
      let items = this.entity.student_test.map( el => el.result )
      let sorted = items.sort( (a,b) => b - a )
      return sorted[0]
    },
    getTries() {
      if ( this.entity.student_test) {
        return this.entity.test_tries - this.entity.student_test.length
      }
      return this.entity.test_tries;
    },
  }
}
</script>

<style scoped>

</style>