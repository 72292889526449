<template>
  <div>

    <h4 @click="expand = !expand"
        :style="`color : ${wsACCENT}`"
        class="pointer"
    >
      <v-icon style="transition: all 0.3s ease" :class="[{'rotate' : !expand }]"  :color="wsACCENT">mdi-menu-down</v-icon>
      {{ topic.name }}
    </h4>

    <v-expand-transition>
      <wsTextViewer
          v-if="expand"
          :value="topic.content"
          :html="topic.text_content"
          :course-id="courseId"
          student
          class="pb-6"
      />
    </v-expand-transition>

  </div>

</template>

<script>
export default {
  name: "lectureTopicViewer",
  props : {
    topic : {
      type : Object,
      default() { return {} }
    },
    courseId : {
      type : String
    },
    expandTrigger : {
      type : Number
    }
  },
  data() {
    return {
      expand : true
    }
  },
  watch : {
    expandTrigger() {
      this.expand = true
    }
  }
}
</script>

<style scoped>
.rotate {
  transform: rotate(-90deg);
}
</style>